import React, { Component } from 'react';
import {
  Row, Col,
  Card, CardBody, CardTitle,
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', disabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const defaultOptions = [
  createOption('One'),
  createOption('Two'),
  createOption('Three'),
];

export default class CreatableDemo extends Component {
  state = {
    isLoading: false,
    options: defaultOptions,
    value: undefined,
  }

  handleChange = (newValue) => {
    this.setState({ value: newValue });
  }

  handleCreate = (inputValue) => {
    this.setState({ isLoading: true });
    
    setTimeout(() => {
      const { options } = this.state;
      const newOption = createOption(inputValue);
      this.setState({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      });
    }, 1000);
  }

  render() {
    const { 
      isLoading, 
      options, 
      value 
    } = this.state;

    return (
      <Card>
        <CardBody>
          <CardTitle>React Select Creatable</CardTitle>
          <p>The Creatable component enables users to create new options along with choosing existing options.</p>
          
          <Row className="mt-4">
            <Col lg={6}>
              <p>Simulate new option creation on backend</p>
              <p className="text-muted">Try to type "Four" and hit enter</p>
              <CreatableSelect
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={this.handleChange}
                onCreateOption={this.handleCreate}
                options={options}
                value={value}
                classNamePrefix="react-select"
              />
            </Col>
            <Col lg={6}>
              <p>Multi-select text input</p>
              <p className="text-muted">Try to type anything and hit enter</p>

              <CreatableInputOnly />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg={6}>
              <p>Single Selection Creatable</p>
              <p className="text-muted">Try to type new color, for example "White" and hit enter</p>
              <CreatableSelect
                options={colourOptions}
                classNamePrefix="react-select"
              />  
            </Col>
            <Col lg={6}>
              <p>Multiple Selection Creatable</p>
              <p className="text-muted">Try to create new color options</p>
              <CreatableSelect
                isMulti
                defaultValue={[colourOptions[4], colourOptions[5]]}
                options={colourOptions}
                classNamePrefix="react-select"
              />  
            </Col>
          </Row>          
        </CardBody>
      </Card>
    );
  }
}

const components = {
  DropdownIndicator: null,
};

export class CreatableInputOnly extends Component {
  state = {
    inputValue: '',
    value: [],
  }

  handleChange = (value) => {
    this.setState({ value });
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  }

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          inputValue: '',
          value: [...value, createOption(inputValue)],
        });
        event.preventDefault();
        break;
      default:
    }
  }

  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Type something and press enter..."
        value={value}
        classNamePrefix="react-select"
      />
    );
  }
}