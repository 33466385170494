import React from 'react';
import { FiGrid, FiAlignJustify, FiSliders, FiEdit3, FiActivity, FiList, FiCalendar, FiLayers } from 'react-icons/fi';

export default [
  {
    label: 'Dashboard',
    to: '/',
    icon: <FiGrid className="icon" size="18" />
  },
  {
    label: 'UI Elements',
    to: null,
    icon: <FiSliders size="18" className="icon" />,
    parent: '/ui/',
    children: [
      { label: 'Buttons', to: '/ui/buttons' },
      { label: 'Sweet Alert', to: '/ui/sweet-alert' },
      { label: 'Notifications', to: '/ui/notifications'},
      { label: 'Cards', to: '/ui/cards'},
      { label: 'Advanced Cards', to: '/ui/cards/cards-advanced'},
      { label: 'Modals', to: '/ui/modals'},
      { label: 'Tabs', to: '/ui/tabs'},
      { label: 'Icons', to: '/ui/icons'},
      { label: 'Misc UI', to: '/ui/misc-ui'},
    ]
  },
  {
    label: 'Forms',
    to: null,
    icon: <FiEdit3 size="18" className="icon" />,
    parent: '/forms/',
    children: [
      { label:"Basic Inputs", to:"/forms/basic-inputs" },
      { label:"File Inputs", to:"/forms/file-inputs" },
      { label:"Input Groups", to:"/forms/input-groups" },
      { label:"React Selects", to:"/forms/react-selects" },
      { label:"React Dates", to:"/forms/react-dates" },
      { label:"File Uploader", to:"/forms/file-uploader" },
      { label:"Slider", to:"/forms/slider" },
    ]
  },  
  { 
    label: 'Charts',
    to: null,
    icon: <FiActivity size="18" className="icon" />,
    parent: '/charts/',
    children: [
      { label:"Chart.js", to:"/charts/chartjs" },
      { label:"Recharts", to:"/charts/recharts" },
    ]
  },  
  { 
    label: 'Tables',
    to: '/tables',
    icon: <FiAlignJustify size="18" className="icon" />,    
  },
  { 
    label: 'Calendar',
    to: '/calendar',
    icon: <FiCalendar size="18" className="icon" />,    
  },
  { 
    label: 'Pages',
    to: null,
    parent: 'pages',
    icon: <FiLayers size="18" className="icon" />,    
    children: [
      { label:"Sign In", to:"/signin" },
      { label:"Sign Up", to:"/signup" },
      { label:"Forgot Password", to:"/forgot" },
    ]
  },  
  {
    label: 'Menu Level 1',
    to: null,
    icon: <FiList size="18" className="icon" />,
    parent: '/404',
    children: [
      {
        label: 'Menu Level 2A',
        to: null,
        parent: '/404-2',
        children: [
          { label: 'Menu Level 3A', to: '/404-2' },
          { label: 'Menu Level 3B', to: '/404-2'  },
        ]
      },
      { label: 'Menu Level 2B', to: '/404'  },
      { label: 'Menu Level 2C', to: '/404'  }  
    ]
  }
];

