import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import SiteHeader from './shared/components/header';
import SiteNavigation from './shared/components/navigation';
import {
  Dashboard,
  Tables,
  Buttons,
  SweetAlert,
  Cards,
  CardsAdvanced,
  Modals,
  Tabs,
  MiscUI,
  BasicInputs,
  FileInputDemo,
  InputGroups,
  ReactSelects,
  ReactDatesDemo,
  FileUploader,
  Recharts,
  Chartjs,
  NotificationsDemo,
  Calendar,
  Slider,
  IconsDemo
} from './views';
import { 
  SignIn,
  SignUp,
  Forgot,
  Page404
} from './pages';
import './app.css';

const RouteMain = ({ component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <TemplateMain>
        <Component {...matchProps} />
      </TemplateMain>
    )} />
  );
}

class TemplateMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      sidebarOff: false,
      mobileSidebarOff: true,
      location: null
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  toggleSidebar = () => {
    if(this.state.width <= 1024) {
      this.setState({ mobileSidebarOff: !this.state.mobileSidebarOff });  
    } else {
      this.setState({ sidebarOff: !this.state.sidebarOff });
    }    
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidUpdate(prevProps) {
    if (this.props.children.props.location.pathname !== prevProps.children.props.location.pathname) {
      if (this.state.width <= 1024) this.toggleSidebar()
    } 
  }

  render() {
    const {
      children
    } = this.props;

    return(
      <Fragment>
        <SiteNavigation 
          off={this.state.sidebarOff} 
          mobileOff={this.state.mobileSidebarOff} 
          toggleSidebar={this.toggleSidebar}         
        />
        <div className="main">
          <div className="screen-overlay" onClick={this.toggleSidebar}></div>
          <SiteHeader toggleSidebar={this.toggleSidebar} />
          <div className="main-content">
            {children}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default class App extends Component {
  render() {  
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot" component={Forgot} />
          <RouteMain exact path="/" component={Dashboard} />
          <RouteMain path="/tables" component={Tables} />
          <RouteMain path="/ui/buttons" component={Buttons} />
          <RouteMain path="/ui/sweet-alert" component={SweetAlert} />
          <RouteMain path="/ui/notifications" component={NotificationsDemo} />
          <RouteMain path="/ui/cards" component={Cards} exact />
          <RouteMain path="/ui/cards/cards-advanced" component={CardsAdvanced} />
          <RouteMain path="/ui/misc-ui" component={MiscUI} />
          <RouteMain path="/ui/modals" component={Modals} />
          <RouteMain path="/ui/tabs" component={Tabs} />
          <RouteMain path="/ui/icons" component={IconsDemo} />
          <RouteMain path="/charts/recharts" component={Recharts} />
          <RouteMain path="/charts/chartjs" component={Chartjs} />
          <RouteMain path="/calendar" component={Calendar} />

          <RouteMain path="/forms/basic-inputs" component={BasicInputs} />
          <RouteMain path="/forms/file-inputs" component={FileInputDemo} />
          <RouteMain path="/forms/input-groups" component={InputGroups} />
          <RouteMain path="/forms/react-selects" component={ReactSelects} />
          <RouteMain path="/forms/react-dates" component={ReactDatesDemo} />
          <RouteMain path="/forms/file-uploader" component={FileUploader} />
          <RouteMain path="/forms/slider" component={Slider} />

          <Route path="/404" component={Page404} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    );
  }
}
