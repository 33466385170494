import React from 'react';
import { 
  Card, CardBody, 
  Form, FormGroup, Label, Input, Button 
} from 'reactstrap';
import { 
  logo,
  screenBg 
} from '../../assets/img';
import '../auth.css';

const pageStyle = {
  backgroundImage: `url(${screenBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}

export default (props) => (
  <div className="auth-page" style={pageStyle}>
    <Card className="auth-card">
      <CardBody>
        <div className="app-logo">
          <img src={logo} alt="Logo" />
          <span>Aboard</span>
        </div>

        <div className="header">
          <h2 className="heading h3">Forgot Password</h2>
          <p className="text-muted">Provide your email address, we will send instructions to reset your password.</p>
        </div>

        <Form>
          <FormGroup>
            <Label for="username">Email</Label>
            <Input id="username" type="email" />
          </FormGroup>
          <Button color="primary" block size="lg">Reset Password</Button>
        </Form>
      </CardBody>
    </Card>
  </div>
);