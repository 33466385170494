import React, { Fragment, Component } from 'react';
import { 
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Collapse,
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
  CustomInput,
  Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { 
  GoChevronUp as IconArrowUp,
  GoKebabVertical as IconMenu,
  GoSync as IconRefresh
} from 'react-icons/go';
import './style.css';

class AdvancedCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.closed ? false : true
    }
  }

  toggleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const {
      title,
      children,
      standard,
      hasSwitch,
      checked,
      options,
      loading,
      loadingText,
      refreshable,
      buttons,
      onChange,
      onClick,
      noPadding,
      scrollable,
      height,
      tabs,
      tabsLine,
      activeTab,
      onTabClick
    } = this.props;

    const {
      isOpen
    } = this.state;

    return (
      <Card>
        { loading &&
          <div className="loading-overlay">
            <div className="loader">
              <div role="status" className="spinner-border text-primary"></div>
              { loadingText && <span className="text">{loadingText}</span> }
            </div>
          </div>
        }
        { tabs === undefined &&
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between align-items-center">
            <CardTitle className="mb-0">{title}</CardTitle>
            <span className="card-controls d-flex">
            { options !== undefined && options.length > 0 &&
              <UncontrolledButtonDropdown group={false}>
                <DropdownToggle color="link">
                  <IconMenu size="16" />
                </DropdownToggle>
                <DropdownMenu right>
                  { 
                    options.map((item, idx) => (
                      <DropdownItem key={idx}>{item.icon} {item.label}</DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            }
            { standard &&
              <Fragment>
                <Button color="link" onClick={this.toggleCollapse}><IconArrowUp size="20" className={isOpen ? '' : 'rotate-180'} /></Button> 
              </Fragment>
            }
            {
              hasSwitch &&
              <CustomInput type="switch" id="customSwitch" name="customSwitch" onChange={onChange} defaultChecked={checked} />
            }
            { refreshable && 
              <Button color="link"><IconRefresh size="18" onClick={onClick} /></Button>
            }
            { buttons !== undefined && buttons.length > 0 &&
              buttons.map((item, idx) => (
                <Button key={idx} size="sm" className="ml-1" color={item.color} onClick={item.onClick}>{item.title}</Button>
              ))
            }
            </span>
          </CardHeader>
        }
        { tabs !== undefined && 
          <Nav pills justified className={classnames({'pills-line': tabsLine})}>
            { 
              tabs.map((item, idx)  => (
                <NavItem key={idx}>
                  <NavLink
                    className={classnames({ active: item.id === activeTab })}
                    onClick={() => onTabClick(item.id)}
                  >
                  {item.title}
                  </NavLink>
                </NavItem>
              ))
            }
          </Nav>
        }
        <Collapse isOpen={isOpen}>
        { scrollable ? 
        
          <PerfectScrollbar style={{height: height}}>
            <CardBody 
              className={
                classnames(
                  {'p-0': noPadding},
                  {'tab-content': tabs !== undefined}
                )
              }
            >
              {children}
            </CardBody>
          </PerfectScrollbar>
          :
          <CardBody 
            className={
              classnames(
                {'p-0': noPadding},
                {'tab-content': tabs !== undefined}
              )
            }
          >
            {children}
          </CardBody>
        } 
        </Collapse>
      </Card>
    );    
  }
}

export default AdvancedCard;