import React, { Component, Fragment } from 'react';
import PageHeader from 'shared/components/page-header';
import {
  Row, Col, FormGroup, Label, Input, Button,
  Card, CardBody, CardTitle
} from 'reactstrap';
import Radio from './Radio';
import Checkbox from './Checkbox';
import { 
  ToastContainer, 
  toast,
  Bounce,
  Slide,
  Flip,
  Zoom
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './custom-styles.css';

// Attach to window. Can be useful to debug
window.toast = toast;

const flags = [
  {
    id: 'disableAutoClose',
    label: 'Disable auto-close'
  },
  {
    id: 'hideProgressBar',
    label: 'Hide progress bar(less fanciness!)'
  },
  {
    id: 'newestOnTop',
    label: 'Newest on top'
  },
  {
    id: 'closeOnClick',
    label: 'Close on click'
  },
  {
    id: 'pauseOnHover',
    label: 'Pause delay on hover'
  },
  {
    id: 'pauseOnFocusLoss',
    label: 'Pause toast when the window loses focus'
  },
  {
    id: 'rtl',
    label: 'Right to left layout'
  },
  {
    id: 'draggable',
    label: 'Allow to drag and close the toast'
  },
];

const transitions = {
  bounce: Bounce,
  slide: Slide,
  zoom: Zoom,
  flip: Flip
};

class NotificationsDemo extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    this.showToast();
  }

  getDefaultState() {
    return {
      ...ToastContainer.defaultProps,
      transition: 'bounce',
      type: 'default',
      progress: '',
      disableAutoClose: false,
      draggable: false
    };
  }

  handleReset = () =>
    this.setState({
      ...this.getDefaultState()
    });

  clearAll = () => toast.dismiss();

  showToast = () => {
    this.toastId = this.state.type === 'default'
      ? toast('Wow so easy !', { progress: this.state.progress })
      : toast[this.state.type]('Wow so easy !', { progress: this.state.progress });
  }

  handleAutoCloseDelay = e => {
    this.setState({
      autoClose: e.target.value > 0 ? parseInt(e.target.value, 10) : 1
    });
  }

  handleRadioOrSelect = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  toggleCheckbox = e => {
    this.setState({
      [e.target.name]: !this.state[e.target.name]
    });
  }

  renderFlags() {
    return flags.map(({ id, label }) => (
      <Checkbox
        key={id}
        id={id}
        label={label}
        onChange={this.toggleCheckbox}
        checked={this.state[id]}
      />
    ));
  }

  render() {
    return (
      <Fragment>
        <PageHeader title="Notifications" description="Customizeable notifications pop-up" />
        <Card>
          <CardBody>
            <CardTitle>Customizing Demo</CardTitle>
            <p>
              By default, all toasts will inherit ToastContainer's props. Props
              defined on toast supersede ToastContainer's props. Props marked with
              * can only be set on the ToastContainer.
            </p>
            <Row>
              <Col md="6">
                <h3 className="h5">Position</h3>
                <Radio
                  options={toast.POSITION}
                  name="position"
                  checked={this.state.position}
                  onChange={this.handleRadioOrSelect}
                />
                <br /><br />
                <h3 className="h5">Type</h3>
                <Radio
                  options={toast.TYPE}
                  name="type"
                  checked={this.state.type}
                  onChange={this.handleRadioOrSelect}
                />
              </Col>
              <Col md="6">
                <h3 className="h5">Options</h3>
                <FormGroup>
                  <Label for="autoClose">Delay (ms) </Label>
                  <Input
                    type="number"
                    name="autoClose"
                    id="autoClose"
                    value={this.state.autoClose}
                    onChange={this.handleAutoCloseDelay}
                    disabled={this.state.disableAutoClose}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="transition">Transition</Label>
                  <Input
                    type="select"
                    name="transition"
                    id="transition"
                    onChange={this.handleRadioOrSelect}
                    value={this.state.transition}
                  >
                    {Object.keys(transitions).map(k => (
                      <option key={k} value={k}>
                        {k}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  {this.renderFlags()}
                </FormGroup>
              </Col>
            </Row>
            <div>
              <Button color="primary" onClick={this.showToast}>
                Show Toast
              </Button>{' '}
              <Button color="danger" onClick={this.clearAll}>
                Clear All
              </Button>{' '}
              <Button color="secondary" onClick={this.handleReset}>
                Reset
              </Button>{' '}
            </div>
            
            <ToastContainer
              {...this.state}
              transition={transitions[this.state.transition]}
              autoClose={this.state.disableAutoClose ? false : this.state.autoClose}
            />
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default NotificationsDemo;
