import React, { Component, Fragment } from 'react';
import { 
  Button,
  ButtonDropdown,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col 
} from 'reactstrap';
import PageHeader from 'shared/components/page-header';
import { 
  GoCheck as IconSave, 
  GoX as IconDelete,
  GoSync as IconRefresh,
  GoCloudDownload as IconDownload,
  GoCloudUpload as IconUpload,
  GoArchive as IconArchive,
  GoGear as IconSettings,
  GoLocation as IconLocation,
  GoPlus as IconAdd,
  GoDash as IconRemove,
  GoSearch as IconSearch,
  GoThumbsup as IconUpvote,
  GoThumbsdown as IconDownvote,
  GoTrashcan as IconTrash
} from 'react-icons/go';

class ButtonDropdownDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    }
  }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  render() {
    return (
      <Fragment>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle color="primary" caret>Button Dropdown</DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Header</DropdownItem>
            <DropdownItem disabled>Action</DropdownItem>
            <DropdownItem>Another Action</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>Another Action</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>{' '}
        <UncontrolledButtonDropdown>
          <DropdownToggle outline color="secondary" caret>Button Dropdown</DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Header</DropdownItem>
            <DropdownItem disabled>Action</DropdownItem>
            <DropdownItem>Another Action</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>Another Action</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Fragment>
    )
  }
}

export default (props) => (
  <div className="demo-only">
    <PageHeader title="Buttons" description="Collection of buttons" />
    <Row>
      <Col>
        <Card>
          <CardBody>
            <CardTitle>Basic</CardTitle>

            <p>Solid Color</p>
            <Button color="primary">Primary</Button>{' '}
            <Button color="secondary">Secondary</Button>{' '}
            <Button color="success">Success</Button>{' '}
            <Button color="info">Info</Button>{' '}
            <Button color="warning">Warning</Button>{' '}
            <Button color="danger">Danger</Button>{' '}
            <Button color="link">Link</Button>

            <p className="mt-5">Outline Buttons</p>
            <Button outline color="primary">Primary</Button>{' '}
            <Button outline color="secondary">Secondary</Button>{' '}
            <Button outline color="success">Success</Button>{' '}
            <Button outline color="info">Info</Button>{' '}
            <Button outline color="warning">Warning</Button>{' '}
            <Button outline color="danger">Danger</Button>

            <p className="mt-5">Button Dropdown</p>
            <ButtonDropdownDemo />
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <CardTitle>Buttons with Icon</CardTitle>

            <p>Solid Color</p>
            <Button color="primary"><IconSave /> Save</Button>{' '}
            <Button color="danger"><IconDelete /> Delete</Button>{' '}
            <Button color="success"><IconRefresh /> Refresh</Button>{' '}
            <Button color="primary"><IconDownload /> Download</Button>{' '}
            <Button color="primary"><IconUpload /> Upload</Button>{' '}
            <Button color="success"><IconArchive /> Archive</Button>{' '}
            <Button color="secondary"><IconSettings /> Settings</Button>{' '}
            <Button color="info"><IconLocation /> Locate</Button>{' '}
            <Button color="success"><IconAdd /> Add</Button>{' '}
            <Button color="danger"><IconRemove /> Remove</Button>{' '}
            <Button color="primary"><IconSearch /> Search</Button>{' '}
            <Button color="success"><IconUpvote /> Upvote</Button>{' '}
            <Button color="secondary"><IconDownvote /> Downvote</Button>{' '}
            <Button color="danger"><IconTrash /> Trash</Button>

            <p className="mt-5">Outline Buttons</p>
            <Button outline color="primary"><IconSave /> Save</Button>{' '}
            <Button outline color="danger"><IconDelete /> Delete</Button>{' '}
            <Button outline color="success"><IconRefresh /> Refresh</Button>{' '}
            <Button outline color="primary"><IconDownload /> Download</Button>{' '}
            <Button outline color="primary"><IconUpload /> Upload</Button>{' '}
            <Button outline color="success"><IconArchive /> Archive</Button>{' '}
            <Button outline color="secondary"><IconSettings /> Settings</Button>{' '}
            <Button outline color="info"><IconLocation /> Locate</Button>{' '}
            <Button outline color="success"><IconAdd /> Add</Button>{' '}
            <Button outline color="danger"><IconRemove /> Remove</Button>{' '}
            <Button outline color="primary"><IconSearch /> Search</Button>{' '}
            <Button outline color="secondary"><IconUpvote /> Upvote</Button>{' '}
            <Button outline color="secondary"><IconDownvote /> Downvote</Button>{' '}
            <Button outline color="danger"><IconTrash /> Trash</Button>

            <p className="mt-5">Icon Link Buttons</p>
            <Button color="link"><IconSave />Save</Button> {'   '}
            <Button color="link" className="text-danger"><IconDelete />Delete</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-success"><IconRefresh />Refresh</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link"><IconDownload />Download</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link"><IconUpload />Upload</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-success"><IconArchive />Archive</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-secondary"><IconSettings />Settings</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link"><IconLocation />Locate</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-success"><IconAdd />Add</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-danger"><IconRemove />Remove</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link"><IconSearch />Search</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-secondary"><IconUpvote />Upvote</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-secondary"><IconDownvote />Downvote</Button> &nbsp;&nbsp;&nbsp;
            <Button color="link" className="text-danger"><IconTrash />Trash</Button>
          
            <p className="mt-5">Icon-only Buttons</p>
            <Button color="primary" size="sm"><IconSave /></Button>{' '}
            <Button color="danger" size="sm"><IconDelete /></Button>{' '}
            <Button color="success"><IconRefresh /></Button>{' '}
            <Button color="primary"><IconDownload /></Button>{' '}
            <Button color="primary"><IconUpload /></Button>{' '}
            <Button color="success" size="lg"><IconArchive /></Button>{' '}
            <Button color="secondary" size="lg"><IconSettings /></Button>{' '}
            <Button outline color="info" size="sm"><IconLocation /></Button>{' '}
            <Button outline color="success" size="sm"><IconAdd /></Button>{' '}
            <Button outline color="danger"><IconRemove /></Button>{' '}
            <Button outline color="primary"><IconSearch /></Button>{' '}
            <Button outline color="success" size="lg"><IconUpvote /></Button>{' '}
            <Button outline color="secondary" size="lg"><IconDownvote /></Button>{' '}
            <Button outline color="danger" size="lg"><IconTrash /></Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);
