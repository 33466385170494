import React from 'react';
import { 
  Card, CardBody, CardTitle,
  Row, Col
} from 'reactstrap';
import {
  LineChart, Line, 
  AreaChart, Area,
  BarChart, Bar,
  ComposedChart,
  ScatterChart, Scatter,
  PieChart, Pie,
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell
} from 'recharts';

const data = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];

const data2 = [
  { x: 100, y: 200, z: 200 },
  { x: 120, y: 100, z: 260 },
  { x: 170, y: 300, z: 400 },
  { x: 140, y: 250, z: 280 },
  { x: 150, y: 400, z: 500 },
  { x: 110, y: 280, z: 200 },
];

const data3 = [
  { name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
  { name: 'Group E', value: 278 }, { name: 'Group F', value: 189 },
];

const data4 = [
  { name: 'Group A', value: 2400 }, { name: 'Group B', value: 4567 },
  { name: 'Group C', value: 1398 }, { name: 'Group D', value: 9800 },
  { name: 'Group E', value: 3908 }, { name: 'Group F', value: 4800 },
];

const data5 = [
  {
    subject: 'Math', A: 120, B: 110, fullMark: 150,
  },
  {
    subject: 'Chinese', A: 98, B: 130, fullMark: 150,
  },
  {
    subject: 'English', A: 86, B: 130, fullMark: 150,
  },
  {
    subject: 'Geography', A: 99, B: 100, fullMark: 150,
  },
  {
    subject: 'Physics', A: 85, B: 90, fullMark: 150,
  },
  {
    subject: 'History', A: 65, B: 85, fullMark: 150,
  },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#7867F5', '#BD0FE1'];

export default (props) => (
  <Row>
    <Col md="6">
      <Card>
        <CardBody>
          <CardTitle>Line Chart</CardTitle>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <LineChart
                data={data}
              >
                <CartesianGrid vertical={false}  />
                <XAxis dataKey="name" height={60} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="natural" dataKey="pv" stroke="#7867F5"  />
                <Line type="natural" dataKey="uv" stroke="#ffa500" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Mix Bar Chart</CardTitle>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <BarChart
                data={data}
              >
                <CartesianGrid  vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" stackId="a" fill="#7867F5" />
                <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
                <Bar dataKey="uv" fill="#ffa500" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Scatter Chart</CardTitle>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <ScatterChart>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis type="number" dataKey="x" name="stature" unit="cm" />
                <YAxis type="number" dataKey="y" name="weight" unit="kg" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter name="A school" data={data2} fill="#7867F5" />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>
    
      <Card>
        <CardBody>
          <CardTitle>Radar Chart</CardTitle>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <RadarChart data={data5}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar name="Mike" dataKey="A" stroke="#7867F5" fill="#7867F5" fillOpacity={0.5} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col md="6">
      <Card>
        <CardBody>
          <CardTitle>Area Chart</CardTitle>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
            <AreaChart
              data={data}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="pv" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Composed Chart</CardTitle>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <ComposedChart
                data={data}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="pv" fill="rgba(120,103,245,.5)" stroke="#7867F5" />
                <Line type="monotone" dataKey="uv" stroke="rgba(120,103,245,.3)" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Pie Chart</CardTitle>
          <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
              <PieChart >
                <Pie dataKey="value" data={data3} outerRadius={120} fill="#8884d8" label labelLine={false}>
                  {
                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                  }
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
            <PieChart>
              <Pie dataKey="value" data={data4} innerRadius={40} outerRadius={100} fill="#82ca9d">
                {
                  data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
);