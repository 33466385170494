import React from 'react';
import { Button, Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import screenfull from 'screenfull';

// Icons used
import { IoIosMenu as IconMenu, 
        IoIosSearch as IconSearch,
        IoIosNotificationsOutline as IconNotification,
        IoIosQrScanner as IconFullscreen,
        IoIosCheckmarkCircle as IconCheck,
        IoIosCloseCircle as IconError,
        IoIosWarning as IconWarning,
        IoIosContact as IconUserDropdown
} from 'react-icons/io';
import {
  FiUser as IconUser,
  FiMail as IconEmail,
  FiSettings as IconSettings,
  FiLock as IconLock,
  FiLogOut as IconSignout
} from 'react-icons/fi';

// Styles
import './styles.css';

export default (props) => (
    <div className="d-flex align-items-center topbar">
        <div className="d-flex align-items-center left">
        <Button color="link" className="sidebar-toggle"><IconMenu size="28" onClick={props.toggleSidebar} /></Button>
        <form className="searchbox d-none d-sm-block">
            <div className="input-group">
                <div className="input-group-prepend"><IconSearch className="icon-search" size="25" /></div>
                <input type="text" name="" placeholder="Search here and hit enter" />
            </div>            
        </form>
    </div>
    <div className="d-flex align-items-center right ml-auto">
      <span className="cursor-pointer"><IconFullscreen size="25" onClick={() => screenfull.toggle()}/></span>
      <UncontrolledDropdown className="has-update">
        <DropdownToggle tag="a">
          <IconNotification size="25" />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-notifications">
          <DropdownItem header>You have 3 new notifications</DropdownItem>
          <DropdownItem tag="a" className="d-flex align-items-center">
            <IconCheck size="22" className="icon" />
            <div className="text">
              <span>Your request has been approved</span>
              <span className="timestamp">11 minutes ago</span>
            </div>
          </DropdownItem>
          <DropdownItem tag="a" className="d-flex align-items-center">
            <IconError size="22" className="icon text-danger" />
            <div className="text">            
              <span>Scheduled job is reporting error</span>
              <span className="timestamp">1 hour ago</span>
            </div>
          </DropdownItem>
          <DropdownItem tag="a" className="d-flex align-items-center">
            <IconWarning size="22" className="icon text-warning" />
            <div className="text">
              <span>System space is almost full</span>
              <span className="timestamp">14 hours ago</span>
            </div>
          </DropdownItem>
          <DropdownItem className="dropdown-footer">
            <a href="#all">See all notifications</a>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      
      <UncontrolledDropdown className="user-menu">
        <DropdownToggle tag="a">
          <IconUserDropdown size="25" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a"><IconUser size="17" /> My Profile</DropdownItem>
          <DropdownItem tag="a" className="d-flex justify-content-between">
            <span><IconEmail size="17" /> Inbox</span>
            <Badge color="primary" pill>5</Badge></DropdownItem>
          <DropdownItem tag="a"><IconSettings size="17" /> Settings</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag="a"><IconLock size="17" /> Lock</DropdownItem>
          <DropdownItem tag="a"><IconSignout size="17" /> Sign Out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      
    </div>

  </div>
);