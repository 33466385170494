import React, { Fragment } from 'react';
import { 
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardImgOverlay,
  Button 
} from 'reactstrap';
import PageHeader from 'shared/components/page-header';

export default (props) => (
  <Fragment>
    <PageHeader title="Cards" description="Collection of cards" />
    <Row>
      <Col lg="6">
        <Card>
          <CardBody>
            <CardTitle>Default Card</CardTitle>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <span>Duis aute irure dolor in reprehenderit in voluptate velit esse.</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="6">
        <Card>
          <CardBody>
            <CardTitle>Card with footer</CardTitle>
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua.</span>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-between align-items-center">
              <span>Card footer</span>
              <Button color="success">Save</Button>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col lg="4">
        <Card>
          <CardImg top width="100%" src="https://source.unsplash.com/collection/1895329/500x300" />
          <CardBody>
            <CardTitle>Card with top image</CardTitle>
            <CardText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
              incididunt ut labore et dolore magna aliqua.
            </CardText>
            <Button color="primary">Detail</Button>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardImg top width="100%" src="https://source.unsplash.com/collection/1895329/500x300" />
          <CardBody>
            <CardTitle>Card with top image</CardTitle>
            <CardText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
              incididunt ut labore et dolore magna aliqua.
            </CardText>
            <div className="text-center">
              <Button color="primary">Detail</Button>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardImg top width="100%" src="https://source.unsplash.com/collection/1895329/500x300" />
          <CardBody>
            <CardTitle>Card with top image</CardTitle>
            <CardText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
              incididunt ut labore et dolore magna aliqua.
            </CardText>
            <div className="text-right">
              <Button color="primary">Detail</Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col lg="4">
        <Card>
          <CardHeader>
            <CardTitle>Card with header background</CardTitle>
          </CardHeader>
          <CardBody>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <span>Duis aute irure dolor in reprehenderit in voluptate velit esse.</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card inverse>
          <CardImg top width="100%" src="https://i.imgur.com/xqVK8HK.jpg" />
          <CardImgOverlay className="card-dark-overlay" >
            <CardTitle>Card Image Overlay</CardTitle>
            <CardText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
              incididunt ut labore et dolore magna aliqua.
            </CardText>
          </CardImgOverlay>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardImg top width="100%" src="https://i.imgur.com/xqVK8HK.jpg" />
          <CardImgOverlay className="card-light-overlay">
            <CardTitle>Card Image Overlay</CardTitle>
            <CardText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
              incididunt ut labore et dolore magna aliqua.
            </CardText>
          </CardImgOverlay>
        </Card>
      </Col>
    </Row>
  </Fragment>
);