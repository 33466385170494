import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainMenu from './MainMenu';
import './styles.css';
import { logo } from '../../../assets/img';
import { FiArrowLeft as IconArrowLeft } from 'react-icons/fi';

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      pristine: true
    }
  }

  changePristine = () => {
    this.setState({
      pristine: false
    })
  }

  render () {
    const {
      toggleSidebar
    } = this.props;
    
    return (
      <div 
        className={`sidebar ${this.props.off ? 'sidebar-off' : ''} ${this.props.mobileOff ? '' : 'mobile-sidebar-on'}`}
      >
        <div className="d-flex justify-content-between">
          <Link to="/" className="app-logo">
            <img src={logo} alt="Logo" />
            <span>Aboard</span>
          </Link>
          <Button color="link" className="px-3 d-lg-none" onClick={toggleSidebar}><IconArrowLeft size="22" /></Button>
        </div>
        <PerfectScrollbar >
          <div className="login-info">
            <div className="user-picture">
              <img src="https://i.imgur.com/a2gbKGq.jpg" className="img-fluid" alt="User Avatar" />
              <h4 className="name">Tyler Duncan</h4>
              <span className="role">Manager</span>
            </div>
          </div>
          <MainMenu pristine={this.state.pristine} changePristine={this.changePristine} />
        </PerfectScrollbar>
      </div>
    );
  }
}

