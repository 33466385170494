import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Card, CardBody, 
  Form, FormGroup, Label, Input, CustomInput, Button 
} from 'reactstrap';
import { 
  logo,
  screenBg 
} from '../../assets/img';
import { IoLogoGoogle, IoLogoFacebook } from 'react-icons/io';
import '../auth.css';

const pageStyle = {
  backgroundImage: `url(${screenBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}

export default (props) => (
  <div className="auth-page" style={pageStyle}>
    <Card className="auth-card">
      <CardBody>
        <div className="app-logo">
          <img src={logo} alt="Logo" />
          <span>Aboard</span>
        </div>

        <div className="header">
          <h2 className="heading h3">Sign In</h2>
          <p className="text-muted">Welcome back, sign in to your account.</p>
        </div>

        <Form>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input id="username" />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input type="password" id="password" />
          </FormGroup>
          <FormGroup className="d-flex justify-content-between">
            <CustomInput type="checkbox" id="remember" label="Remember me" />
            <Link to="/forgot">Forgot Password?</Link>
          </FormGroup>
          <Button color="primary" block size="lg">Sign In</Button>
        </Form>

        <span className='text-separator'>OR</span>

        <div className="social-login">
          <Button outline color="google" block><IoLogoGoogle size="18" /> Sign in with Google</Button>
          <Button outline color="facebook" block><IoLogoFacebook size="18" /> Sign in with Facebook</Button>
        </div>
        <p className="text-center mt-3">Don't have an account? <Link to="/signup">Sign Up</Link></p>
      </CardBody>
    </Card>
  </div>
);