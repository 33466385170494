import React, { Fragment, Component } from 'react';
import PageHeader from 'shared/components/page-header';
import {
  Row, Col,
  Card, CardBody,
  InputGroup, InputGroupText, InputGroupAddon, Input, CustomInput, InputGroupButtonDropdown,
  Button,
  DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { 
  GoCheck as IconCheck, 
  GoPerson as IconUser,
  GoMail as IconEmail,
  GoLock as IconPassword
} from 'react-icons/go';

export default (props) => (
  <Fragment>
    <PageHeader title="Input Groups" description="Collection of input group" />
    <Card>
      <CardBody>
        <Row>
          <Col md="4">
            <p className="my-3">Icon Addon</p>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><IconUser /></InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Username"/>
            </InputGroup>
            <br />
            <InputGroup>
              <Input placeholder="Email address"/>
              <InputGroupAddon addonType="append">
                <InputGroupText><IconEmail /></InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><IconPassword /></InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Confirm password" />
              <InputGroupAddon addonType="append">
                <InputGroupText><IconCheck /></InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="4">
            <p className="my-3">Button</p>
            <InputGroup>
              <InputGroupAddon addonType="prepend"><Button>Search</Button></InputGroupAddon>
              <Input placeholder="Enter keyword.." />
            </InputGroup>
            <br />
            <InputGroup>
              <Input placeholder="Enter keyword.."/>
              <InputGroupAddon addonType="append"><Button color="primary">Search</Button></InputGroupAddon>
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon addonType="append"><Button color="success">Save</Button></InputGroupAddon>
              <Input placeholder="Enter keyword.."/>
              <InputGroupAddon addonType="append"><Button color="danger">Delete</Button></InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="4">
            <p className="my-3">Text Addon</p>
            <InputGroup>
              <Input placeholder="username" />
              <InputGroupAddon addonType="append">@example.com</InputGroupAddon>
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon addonType="prepend">@</InputGroupAddon>
              <Input placeholder="Username" />
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <Input placeholder="Enter price" type="number" />
              <InputGroupAddon addonType="append">.00</InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <br /><br />
        <Row>
          <Col md="4">
            <p className="my-3">Sizing</p>
            <InputGroup size="lg">
              <InputGroupAddon addonType="prepend">Large</InputGroupAddon>
              <Input />
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon addonType="prepend">Normal</InputGroupAddon>
              <Input />
            </InputGroup>
            <br />
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">Small</InputGroupAddon>
              <Input />
            </InputGroup>
          </Col>
          <Col md="4">
            <p className="my-3">Radio and Checkbox Addon</p>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <CustomInput type="checkbox" id="customCheckbox" />
                </InputGroupText>
              </InputGroupAddon>
              <Input />
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <CustomInput type="radio" name="customRadio" id="customRadio1" />
                </InputGroupText>
              </InputGroupAddon>
              <Input />
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <CustomInput type="radio" name="customRadio" id="customRadio2" />
                </InputGroupText>
              </InputGroupAddon>
              <Input />
            </InputGroup>
          </Col>
          <Col md="4">
            <p className="my-3">Dropdown Addon</p>
            <DropdownAddonDemo />
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Fragment> 
);

export class DropdownAddonDemo extends Component {
  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggleSplit = this.toggleSplit.bind(this);
    this.state = {
      dropdownOpen: [false, false],
      splitButtonOpen: false
    };
  }

  toggleDropDown(idx) {
    let dropdownOpen = this.state.dropdownOpen;
    dropdownOpen[idx] = !dropdownOpen[idx];

    this.setState({
      dropdownOpen: dropdownOpen
    });
  }

  toggleSplit() {
    this.setState({
      splitButtonOpen: !this.state.splitButtonOpen
    });
  }

  render() {
    return (
      <Fragment>
        <InputGroup>
          <Input />
          <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen[1]} toggle={() => this.toggleDropDown(1)}>
            <DropdownToggle caret color="primary">
              Button Dropdown
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem disabled>Action</DropdownItem>
              <DropdownItem>Another Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Another Action</DropdownItem>
            </DropdownMenu>
          </InputGroupButtonDropdown>
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.dropdownOpen[2]} toggle={() => this.toggleDropDown(2)}>
            <DropdownToggle caret outline>
              Button Dropdown
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem disabled>Action</DropdownItem>
              <DropdownItem>Another Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Another Action</DropdownItem>
            </DropdownMenu>
          </InputGroupButtonDropdown>
          <Input />
        </InputGroup>
        <br />
        <InputGroup>
          <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.splitButtonOpen} toggle={this.toggleSplit}>
            <Button outline>Split Button</Button>
            <DropdownToggle split outline />
            <DropdownMenu>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem disabled>Action</DropdownItem>
              <DropdownItem>Another Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Another Action</DropdownItem>
            </DropdownMenu>
          </InputGroupButtonDropdown>
          <Input />
        </InputGroup>
      </Fragment>
    );
  }
}