import React, { Fragment } from 'react';
import PageHeader from 'shared/components/page-header';
import {
  Card, CardBody, CardTitle, CardHeader,
  Row, Col
} from 'reactstrap';
import { FaEnvelope, FaAccessibleIcon, FaAddressBook, FaAdjust, FaAmazon, FaAlignCenter, FaAtlas, FaAward, FaBalanceScale } from 'react-icons/fa';
import { IoIosAdd, IoIosAddCircle, IoIosAlarm, IoIosAlert, IoIosClock, IoIosAirplane, IoIosAnalytics, IoIosChatboxes, IoIosClipboard } from 'react-icons/io';
import { MdAccessAlarm, MdAccountBalanceWallet, MdAccountCircle, MdBackup, MdBatteryCharging20, MdCancel, MdChatBubble, MdDesktopMac, MdDirectionsBike } from 'react-icons/md';
import { TiAdjustBrightness, TiAnchor, TiArchive, TiCamera, TiDatabase, TiCalendar, TiChartPie, TiContacts, TiDeviceLaptop } from 'react-icons/ti';
import { GoAlert, GoArchive, GoBeaker, GoCalendar, GoCircleSlash, GoClock, GoDatabase, GoDiff, GoEye, GoFileDirectory } from 'react-icons/go';
import { FiBattery, FiCalendar, FiCameraOff, FiActivity, FiAlertOctagon, FiArchive, FiBarChart, FiBluetooth, FiDownloadCloud } from 'react-icons/fi';
import { Gi3DHammer, GiAbacus, GiBackboneShell, GiCactus, GiDaggerRose, GiDamagedHouse, GiEagleHead, GiEarthCrack } from 'react-icons/gi';
import { WiBarometer, WiMoonAltFull, WiDayCloudy, WiDayHaze, WiMeteor, WiFog, WiCloudyGusts } from 'react-icons/wi';
import { DiAndroid, DiBugsense, DiGitBranch, DiGitCommit, DiGitCompare, DiHeroku, DiJira, DiLinux, DiMarkdown } from 'react-icons/di';

export default () => (
  <Fragment>
    <PageHeader 
      title="Icons" 
      description="Include popular icons in your React projects easily. Utilize ES6 imports to include only the icons used in the project." 
    />
    <Row>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Font Awesome</CardTitle>            
            <span className="text-muted">1,500+ icons</span>
          </CardHeader>
          <CardBody>
            <FaEnvelope size="20" className="mr-2" />
            <FaAccessibleIcon size="20" className="mr-2" />
            <FaAddressBook size="20" className="mr-2" />
            <FaAdjust size="20" className="mr-2" />
            <FaAlignCenter size="20" className="mr-2" />
            <FaAmazon size="20" className="mr-2" />
            <FaAtlas size="20" className="mr-2" />
            <FaAward size="20" className="mr-2" />
            <FaBalanceScale size="20" className="mr-2" /> 
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Ionicons</CardTitle>            
            <span className="text-muted">1,000+ icons</span>
          </CardHeader>
          <CardBody>
            <IoIosAdd size="20" className="mr-2" />
            <IoIosAddCircle size="20" className="mr-2" />
            <IoIosAirplane size="20" className="mr-2" />
            <IoIosAlarm size="20" className="mr-2" />
            <IoIosAlert size="20" className="mr-2" />
            <IoIosAnalytics size="20" className="mr-2" />
            <IoIosChatboxes size="20" className="mr-2" />
            <IoIosClipboard size="20" className="mr-2" />
            <IoIosClock size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Material Design Icons</CardTitle>            
            <span className="text-muted">950+ icons</span>
          </CardHeader>
          <CardBody>
            <MdAccessAlarm size="20" className="mr-2" />
            <MdAccountBalanceWallet size="20" className="mr-2" />
            <MdAccountCircle size="20" className="mr-2" />
            <MdBackup size="20" className="mr-2" />
            <MdBatteryCharging20 size="20" className="mr-2" />
            <MdCancel size="20" className="mr-2" />
            <MdChatBubble size="20" className="mr-2" />
            <MdDesktopMac size="20" className="mr-2" />
            <MdDirectionsBike size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Typicons</CardTitle>            
            <span className="text-muted">340+ icons</span>
          </CardHeader>
          <CardBody>
            <TiAdjustBrightness size="20" className="mr-2" />
            <TiAnchor size="20" className="mr-2" />
            <TiArchive size="20" className="mr-2" />
            <TiCalendar size="20" className="mr-2" />
            <TiCamera size="20" className="mr-2" />
            <TiChartPie size="20" className="mr-2" />
            <TiContacts size="20" className="mr-2" />
            <TiDatabase size="20" className="mr-2" />
            <TiDeviceLaptop size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Github</CardTitle>            
            <span className="text-muted">180+ icons</span>
          </CardHeader>
          <CardBody>
            <GoAlert size="20" className="mr-2" />
            <GoArchive size="20" className="mr-2" />
            <GoBeaker size="20" className="mr-2" />
            <GoCalendar size="20" className="mr-2" />
            <GoCircleSlash size="20" className="mr-2" />
            <GoClock size="20" className="mr-2" />
            <GoDatabase size="20" className="mr-2" />
            <GoDiff size="20" className="mr-2" />
            <GoEye size="20" className="mr-2" />
            <GoFileDirectory size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Feather</CardTitle>            
            <span className="text-muted">280+ icons</span>
          </CardHeader>
          <CardBody>
            <FiBattery size="20" className="mr-2" />
            <FiCalendar size="20" className="mr-2" />
            <FiCameraOff size="20" className="mr-2" />
            <FiActivity size="20" className="mr-2" />
            <FiArchive size="20" className="mr-2" />
            <FiAlertOctagon size="20" className="mr-2" />
            <FiBarChart size="20" className="mr-2" />
            <FiBluetooth size="20" className="mr-2" />
            <FiDownloadCloud size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Game Icons</CardTitle>            
            <span className="text-muted">3,350+ icons</span>
          </CardHeader>
          <CardBody>
            <Gi3DHammer size="20" className="mr-2" />
            <GiAbacus size="20" className="mr-2" />
            <GiBackboneShell size="20" className="mr-2" />
            <GiCactus size="20" className="mr-2" />
            <GiDaggerRose size="20" className="mr-2" />
            <GiDamagedHouse size="20" className="mr-2" />
            <GiEagleHead size="20" className="mr-2" />
            <GiEarthCrack size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Weather Icons</CardTitle>            
            <span className="text-muted">240+ icons</span>
          </CardHeader>
          <CardBody>
            <WiBarometer size="20" className="mr-2" />
            <WiDayCloudy size="20" className="mr-2" />
            <WiDayHaze size="20" className="mr-2" />
            <WiMeteor size="20" className="mr-2" />
            <WiFog size="20" className="mr-2" />
            <WiCloudyGusts size="20" className="mr-2" />
            <WiDayHaze size="20" className="mr-2" />
            <WiMoonAltFull size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
      <Col lg="4">
        <Card>
          <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
            <CardTitle>Devicons</CardTitle>            
            <span className="text-muted">190+ icons</span>
          </CardHeader>
          <CardBody>            

            <DiAndroid size="20" className="mr-2" />
            <DiBugsense size="20" className="mr-2" />
            <DiGitBranch size="20" className="mr-2" />
            <DiGitCommit size="20" className="mr-2" />
            <DiGitCompare size="20" className="mr-2" />
            <DiHeroku size="20" className="mr-2" />
            <DiJira size="20" className="mr-2" />
            <DiLinux size="20" className="mr-2" />
            <DiMarkdown size="20" className="mr-2" />
            <span>and more...</span>
          </CardBody>
        </Card>
      </Col>
    </Row>    
  </Fragment>
);