import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import events from './events';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './custom-style.css';

const localizer = momentLocalizer(moment);

const MyCalendar = props => (
  <Card>
    <CardBody className="p-0">
      <div className="calendar-container">
        <Calendar
          events={events}
          step={60}
          showMultiDayTimes
          defaultDate={new Date(2019, 3, 1)}
          localizer={localizer}
        />
      </div>    
    </CardBody>
  </Card>
  
)

export default MyCalendar;