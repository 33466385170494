import React from 'react';
import { Link } from 'react-router-dom';
import { illustration404 } from '../../assets/img';

export default (props) => (
  <div className="d-flex align-items-center justify-content-center h-100">
    <div className="text-center">
      <img src={illustration404} alt="404" width="200" className="mb-4" />
      <h2>Page Not Found</h2>
      <p>
        Looks like the page you are trying to visit doesn't exist. <br/>
        Please check the URL and try again.
      </p>
      <Link to="/" className="mt-3">Go to Dashboard</Link>
    </div>
  </div>
);