import React from 'react';
import { CustomInput } from 'reactstrap';

const Checkbox = ({ label, onChange, id, checked }) => (
  <CustomInput
    id={id}
    type="checkbox"
    name={id}
    label={label}
    checked={checked}
    onChange={onChange}
  />
);

export default Checkbox;
