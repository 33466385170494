import React, { Component, Fragment } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { FiChevronRight } from 'react-icons/fi';
import menu from './menu';

const IconCaret = () => <FiChevronRight className="icon-caret" />;

class MenuItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapseOpen: false
    }
  }

  render() {
    const {
      id,
      label,
      to,
      icon,
      parent,
      submenu,
      pristine,
      changePristine
    } = this.props;

    return (
      <Route
        path={to}
        children={
          ({match}) => {
            let parentOpen = match !== null && match.url.includes(parent);            
            
            return (
              <NavItem key={id}>          
                {to !== null ?
                <NavLink to={to} className="nav-link" activeClassName="active" exact>{icon} {label}</NavLink>
                :
                <Fragment>
                  <button 
                    id={id}
                    className="nav-link" 
                    onClick={() => {
                      if(!pristine || !parentOpen) {
                        this.setState({
                          collapseOpen: !this.state.collapseOpen
                        })                      
                      }                      
                      changePristine();
                    }}
                  >
                    {icon} {label} <IconCaret />
                  </button>
                  
                  <Collapse isOpen={pristine ? parentOpen : this.state.collapseOpen}>
                    <Nav vertical className="submenu">
                      {submenu.map((item, idx) => 
                        <MenuItem 
                          key={`submenu-${parent}-${idx}`} 
                          id={`submenu-${parent}-${idx}`} 
                          to={item.to}
                          label={item.label} 
                          icon={null}
                          parent={item.parent}
                          submenu={item.children}
                          pristine={pristine}
                          changePristine={changePristine}
                        />
                      )}
                    </Nav>
                  </Collapse>
                  
                </Fragment>
                }              
              </NavItem>          
            )
          }
        }
      />
    );
  }
}

export default props => (
  <nav className="main-navigation">
    <Nav vertical>
      {menu.map((item, idx) => 
        <MenuItem 
          key={`menuL1-${idx}`} 
          id={`menuL1-${idx}`} 
          to={item.to}
          label={item.label} 
          icon={item.icon} 
          parent={item.parent}
          submenu={item.children}
          pristine={props.pristine}
          changePristine={props.changePristine}
        />
      )}              
    </Nav>            
  </nav>
)

