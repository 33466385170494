import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Card, CardBody, 
  Form, FormGroup, Label, Input, Button 
} from 'reactstrap';
import { 
  logo,
  screenBg 
} from '../../assets/img';
import { IoLogoGoogle, IoLogoFacebook } from 'react-icons/io';
import '../auth.css';

const pageStyle = {
  backgroundImage: `url(${screenBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}

export default (props) => (
  <div className="auth-page" style={pageStyle}>
    <Card className="auth-card">
      <CardBody>
        <div className="app-logo">
          <img src={logo} alt="Logo" />
          <span>Aboard</span>
        </div>

        <div className="header">
          <h2 className="heading h3">Sign Up</h2>
          <p className="text-muted">Create your account now to access special features.</p>
        </div>

        <Form>
          <FormGroup>
            <Label for="username">Email</Label>
            <Input id="username" type="email" />
          </FormGroup>          
          <FormGroup>
            <Label for="password">Password</Label>
            <Input type="password" id="password" />
          </FormGroup>
          <p className="notes">By signing up, you confirm that you’ve read and accepted our <a href="#terms">Termas</a> and <a href="#pp">Privacy Policy.</a></p>
          <Button color="primary" block size="lg">Sign Up</Button>
        </Form>

        <span className='text-separator'>OR</span>

        <div className="social-login">
          <Button outline color="google" block><IoLogoGoogle size="18" /> Continue with Google</Button>
          <Button outline color="facebook" block><IoLogoFacebook size="18" /> Continue with Facebook</Button>
        </div>

        <p className="text-center mt-3">Already have an account? <Link to="/signin">Sign In</Link></p>
      </CardBody>
    </Card>
  </div>
);