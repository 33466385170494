import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import { Sugar } from 'react-preloaders';

ReactDOM.render(
  <Fragment>
    <App />
    <Sugar background="#F4F6F9" color="#4c4e55" />
  </Fragment>  
, document.getElementById('root'));

