import React, { Component, Fragment } from 'react';
import PageHeader from 'shared/components/page-header';
import {
  Card, CardBody, CardTitle,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

class ModalsDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalOpen2: false,
    }
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  }

  toggleModal2 = () => {
    this.setState({
      modalOpen2: !this.state.modalOpen2,
    });
  }

  render() {
    return (
      <Fragment>
        <Button color="secondary" onClick={this.toggleModal}>Default</Button> {" "}
        <Button color="secondary" onClick={this.toggleModal2}>Fullwidth</Button>
        <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Modal Title</ModalHeader>
          <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna 
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>Do Something</Button>
            <Button color="light" onClick={this.toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalOpen2} toggle={this.toggleModal2} className="modal-fullwidth">
          <ModalHeader toggle={this.toggleModal2}>Modal Title</ModalHeader>
          <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna 
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal2}>Do Something</Button>
            <Button color="light" onClick={this.toggleModal2}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default () => (
  <Fragment>
    <PageHeader title="Modals" description="Modal pop-up with various size and transitions" />
    <Card>
      <CardBody>
        <CardTitle>Modals</CardTitle>

        <ModalsDemo />
      </CardBody>
    </Card>

  </Fragment>
);