import React, { Component, Fragment } from 'react';
import PageHeader from 'shared/components/page-header';
import { 
  Row, Col,
  Card, CardBody, CardTitle, CardColumns,
  Button,
  Tooltip,
  Popover, PopoverHeader, PopoverBody,
  Badge,
  Alert,
  Progress,
  Spinner,
  Pagination, PaginationItem, PaginationLink,
  Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import { FaQuestionCircle as IconHelp } from 'react-icons/fa';

class MiscUI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: [false, false, false, false, false],
      popoverOpen: [false, false, false, false, false]
    }
  }

  toggleTooltip = (idx) => {
    let open = this.state.tooltipOpen;
    open[idx] = !open[idx];

    this.setState({
      tooltipOpen: open
    });
  }

  togglePopover = (idx) => {
    let open = this.state.popoverOpen;
    open[idx] = !open[idx];

    this.setState({
      popoverOpen: open
    });
  }

  render() {
    
    return (
      <Fragment>
        <PageHeader title="Misc. UI" description="Alerts, tooltips, popovers, badges and others" />

        <CardColumns className="column-count-2">
          
            <Card>
              <CardBody>
                <CardTitle>Tooltips and Popovers</CardTitle>
                <p>Mouse over help icon and button to trigger tooltip/popover</p>
                <Row>
                  <Col>                  
                    <p>Left <IconHelp id="tooltip-left" size="14" color="#b1b1b1" /></p>
                    <Tooltip trigger="hover" placement="left" isOpen={this.state.tooltipOpen[0]} toggle={() => this.toggleTooltip(0)} target="tooltip-left">
                      Left Tooltip
                    </Tooltip>

                    <p>Right <IconHelp id="tooltip-right" size="14" color="#b1b1b1" /></p>
                    <Tooltip trigger="hover" placement="right" isOpen={this.state.tooltipOpen[1]} toggle={() => this.toggleTooltip(1)} target="tooltip-right">
                      Right Tooltip
                    </Tooltip>

                    <p>Top <IconHelp id="tooltip-top" size="14" color="#b1b1b1" /></p>
                    <Tooltip trigger="hover" placement="top" isOpen={this.state.tooltipOpen[2]} toggle={() => this.toggleTooltip(2)} target="tooltip-top">
                      Top Tooltip
                    </Tooltip>

                    <p>Bottom <IconHelp id="tooltip-bottom" size="14" color="#b1b1b1" /></p>
                    <Tooltip trigger="hover" placement="bottom" isOpen={this.state.tooltipOpen[3]} toggle={() => this.toggleTooltip(3)} target="tooltip-bottom">
                      Bottom Tooltip
                    </Tooltip>

                    <p>On-Click <IconHelp id="tooltip-click" size="14" color="#b1b1b1" /></p>
                    <Tooltip trigger="click" placement="right" isOpen={this.state.tooltipOpen[4]} toggle={() => this.toggleTooltip(4)} target="tooltip-click">
                      On-Click Tooltip
                    </Tooltip>
                  </Col>
                  <Col>
                    <Button color="secondary" id="popover-left" block className="mb-1">Left</Button>
                    <Popover trigger="hover" placement="left" isOpen={this.state.popoverOpen[0]} toggle={() => this.togglePopover(0)} target="popover-left">
                      <PopoverHeader>Popover Title</PopoverHeader>
                      <PopoverBody>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </PopoverBody>
                    </Popover>
                    
                    <Button color="secondary" id="popover-right" block className="mb-1">Right</Button>
                    <Popover trigger="hover" placement="right" isOpen={this.state.popoverOpen[1]} toggle={() => this.togglePopover(1)} target="popover-right">
                      <PopoverHeader>Popover Title</PopoverHeader>
                      <PopoverBody>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </PopoverBody>
                    </Popover>
                    
                    <Button color="secondary" id="popover-top" block className="mb-1">Top</Button>
                    <Popover trigger="hover" placement="top" isOpen={this.state.popoverOpen[2]} toggle={() => this.togglePopover(2)} target="popover-top">
                      <PopoverHeader>Popover Title</PopoverHeader>
                      <PopoverBody>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </PopoverBody>
                    </Popover>
                    
                    <Button color="secondary" id="popover-bottom" block className="mb-1">Bottom</Button>
                    <Popover trigger="hover" placement="bottom" isOpen={this.state.popoverOpen[3]} toggle={() => this.togglePopover(3)} target="popover-bottom">
                      <PopoverHeader>Popover Title</PopoverHeader>
                      <PopoverBody>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </PopoverBody>
                    </Popover>
                    
                    <Button color="secondary" id="popover-click" block>On-Click</Button>
                    <Popover trigger="click" placement="right" isOpen={this.state.popoverOpen[4]} toggle={() => this.togglePopover(4)} target="popover-click">
                      <PopoverHeader>Popover Title</PopoverHeader>
                      <PopoverBody>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </PopoverBody>
                    </Popover>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            
            <Card>
              <CardBody>
                <CardTitle>Spinner</CardTitle>
                <Spinner color="light" /> {" "}
                <Spinner color="secondary" /> {" "} 
                <Spinner color="dark" /> {" "}
                <Spinner color="primary" /> {" "} 
                <Spinner color="success" /> {" "}
                <Spinner color="warning" /> {" "}
                <Spinner color="danger" /> {" "}
                <Spinner color="info" />

                <p className="mt-3">Growing Spinner</p>
                <Spinner type="grow" color="primary" /> {" "}
                <Spinner type="grow" color="secondary" /> {" "}
                <Spinner type="grow" color="success" /> {" "}
                <Spinner type="grow" color="danger" /> {" "}
                <Spinner type="grow" color="warning" /> {" "}
                <Spinner type="grow" color="info" /> {" "}
                <Spinner type="grow" color="light" /> {" "}
                <Spinner type="grow" color="dark" />

                <p className="mt-3">Small and Custom Size</p>
                <Spinner color="primary" size="sm" /> {" "} 
                <Spinner color="primary" size="sm" type="grow" /> {" "} 
                <Spinner color="primary" style={{ width: '1.5rem', height: '1.5rem' }} /> {" "} 
                <Spinner color="primary" style={{ width: '1.5rem', height: '1.5rem' }} type="grow" />
              </CardBody>
            </Card>
        
            <Card>
              <CardBody>
                <CardTitle>Alerts</CardTitle>
                <Alert color="primary">This is a primary alert — check it out!</Alert>
                <Alert color="secondary">This is a secondary alert — check it out!</Alert>
                <Alert color="success">This is a success alert — check it out!</Alert>
                <Alert color="danger">This is a danger alert — check it out!</Alert>
                <Alert color="warning">This is a warning alert — check it out!</Alert>
                <Alert color="info">This is a info alert — check it out!</Alert>
                <Alert color="light">This is a light alert — check it out!</Alert>
                <Alert color="dark">This is a dark alert — check it out!</Alert>
              </CardBody>
            </Card>
          
            <Card>
              <CardBody>
                <CardTitle>Badges</CardTitle>
                <Badge color="primary">Primary</Badge> {" "}
                <Badge color="secondary">Secondary</Badge> {" "}
                <Badge color="success">Success</Badge> {" "}
                <Badge color="danger">Danger</Badge> {" "}
                <Badge color="warning">Warning</Badge> {" "}
                <Badge color="info">Info</Badge> {" "}
                <Badge color="light">Light</Badge> {" "}
                <Badge color="dark">Dark</Badge> {" "}
                <br /><br />
                <Badge color="primary" pill>Primary</Badge> {" "}
                <Badge color="secondary" pill>Secondary</Badge> {" "}
                <Badge color="success" pill>Success</Badge> {" "}
                <Badge color="danger" pill>Danger</Badge> {" "}
                <Badge color="warning" pill>Warning</Badge> {" "}
                <Badge color="info" pill>Info</Badge> {" "}
                <Badge color="light" pill>Light</Badge> {" "}
                <Badge color="dark" pill>Dark</Badge> {" "}
                <br /><br />
                <Button color="secondary" outline>
                  Updates <Badge color="primary">5</Badge>
                </Button> {" "}
                <Button color="secondary" outline>
                  Updates <Badge color="danger">5</Badge>
                </Button> {" "}
                <Button color="secondary" outline>
                  Updates <Badge color="warning">5</Badge>
                </Button> {" "}
                <Button color="secondary" outline>
                  Updates <Badge color="success">5</Badge>
                </Button>
                <br /><br />
                <Button color="primary">
                  Updates <Badge color="primary">5</Badge>
                </Button> {" "}
                <Button color="primary">
                  Updates <Badge color="danger">5</Badge>
                </Button> {" "}
                <Button color="primary">
                  Updates <Badge color="warning">5</Badge>
                </Button> {" "}
                <Button color="primary">
                  Updates <Badge color="success">5</Badge>
                </Button>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle>Progress</CardTitle>
                <Progress /> <br />
                <Progress value={50} color="success" /> <br />
                <Progress value={75} color="warning" style={{ height: '.5em' }} /> <br />
                <Progress multi> <br />
                  <Progress bar value="15" />
                  <Progress bar color="success" value="30" />
                  <Progress bar color="info" value="25" />
                  <Progress bar color="warning" value="20" />
                  <Progress bar color="danger" value="5" />
                </Progress> <br />
                <Progress value="25">25%</Progress> <br />
                <Progress value={75}>You're almost there!</Progress> <br />
                <Progress color="success" value="100">You did it!</Progress> <br />
                <Progress multi> <br />
                  <Progress bar value="15">Meh</Progress>
                  <Progress bar color="success" value="30">Wow!</Progress>
                  <Progress bar color="info" value="25">Cool</Progress>
                  <Progress bar color="warning" value="20">20%</Progress>
                  <Progress bar color="danger" value="5">!!</Progress>
                </Progress> <br />
                <Progress striped color="success" value="25" /> <br />
                <Progress striped animated color="info" value={50} /> <br /><br />

                <p>With Max Value</p>
                <div className="text-center">1 of 5</div>
                <Progress value="1" max="5" />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle>Pagination</CardTitle>
                <Pagination>
                  <PaginationItem><PaginationLink previous href="#prev" /></PaginationItem>
                  <PaginationItem><PaginationLink href="#1">1</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#2">2</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#3">3</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#4">4</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#5">5</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink next href="#next" /></PaginationItem>
                </Pagination>

                <Pagination>
                  <PaginationItem disabled><PaginationLink previous href="#prev" /></PaginationItem>
                  <PaginationItem active><PaginationLink href="#1">1</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#2">2</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#3">3</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#4">4</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#5">5</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink next href="#next" /></PaginationItem>
                </Pagination>

                <p>Sizing</p>
                <Pagination size="lg">
                  <PaginationItem><PaginationLink previous href="#prev" /></PaginationItem>
                  <PaginationItem><PaginationLink href="#1">1</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#2">2</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#3">3</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink next href="#next" /></PaginationItem>
                </Pagination>

                <Pagination size="sm">
                  <PaginationItem><PaginationLink previous href="#prev" /></PaginationItem>
                  <PaginationItem><PaginationLink href="#1">1</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#2">2</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink href="#3">3</PaginationLink></PaginationItem>
                  <PaginationItem><PaginationLink next href="#next" /></PaginationItem>
                </Pagination>
              </CardBody>
            </Card>
        
            <Card>
              <CardBody>
                <CardTitle>Breadcrumbs</CardTitle>
                <Breadcrumb>
                  <BreadcrumbItem><a href="#h">Home</a></BreadcrumbItem>
                  <BreadcrumbItem active>Library</BreadcrumbItem>
                </Breadcrumb>
                <Breadcrumb>
                  <BreadcrumbItem><a href="#h">Home</a></BreadcrumbItem>
                  <BreadcrumbItem><a href="#l">Library</a></BreadcrumbItem>
                  <BreadcrumbItem active>Data</BreadcrumbItem>
                </Breadcrumb>

                <br />

                <p>No Background</p>
                <Breadcrumb listClassName="bg-transparent pl-0">
                  <BreadcrumbItem><a href="#h">Home</a></BreadcrumbItem>
                  <BreadcrumbItem><a href="#l">Library</a></BreadcrumbItem>
                  <BreadcrumbItem active>Data</BreadcrumbItem>
                </Breadcrumb>
              </CardBody>
            </Card>
        </CardColumns>
      </Fragment>
    );
  }
}

export default MiscUI;
