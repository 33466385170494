import React, { Fragment, Component } from 'react';
import { 
  TabContent, TabPane, Nav, NavItem, NavLink, 
  Card, CardBody, CardTitle, Row, Col,
  UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu
} from 'reactstrap';
import classnames from 'classnames';

class TabsDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: [1,1,1,1],
      dropdownOpen: false
    };
  }

  toggleTab(idx, id) {
    let active = this.state.activeTab;
    active[idx] = id;

    this.setState({
      activeTab: active
    });
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle>Tabs Inside Card</CardTitle>
                <Nav tabs>
                  <NavItem>
                    <NavLink 
                      href="#"
                      className={classnames({ active: this.state.activeTab[0] === 1 })}
                      onClick={() => this.toggleTab(0, 1)}
                    >
                      My Profile
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav>
                    <DropdownToggle 
                      nav 
                      caret
                      className={classnames({ active: this.state.activeTab[0] === 'd1' || this.state.activeTab[0] === 'd2' })}
                    >
                      Transactions
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className={classnames({ active: this.state.activeTab[0] === 'd1' })}
                        onClick={() => this.toggleTab(0, 'd1')}
                      >
                        Purchases
                      </DropdownItem>
                      <DropdownItem
                        className={classnames({ active: this.state.activeTab[0] === 'd2' })}
                        onClick={() => this.toggleTab(0, 'd2')}
                      >
                        Sales
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem disabled>Transaction Reports</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink 
                      href="#"
                      className={classnames({ active: this.state.activeTab[0] === 2 })}
                      onClick={() => this.toggleTab(0, 2)}
                    >
                      Settings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink disabled href="#">Reports</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab[0]}>
                  <TabPane tabId={1}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </TabPane>
                  <TabPane tabId="d1">                
                    Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                  </TabPane>
                  <TabPane tabId="d2">                
                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </TabPane>
                  <TabPane tabId={2}>                
                    Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle>Pills Inside Card</CardTitle>
                <Nav pills>
                  <NavItem>
                    <NavLink 
                      href="#"
                      className={classnames({ active: this.state.activeTab[1] === 1 })}
                      onClick={() => this.toggleTab(1, 1)}
                    >
                      My Profile
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav>
                    <DropdownToggle 
                      nav 
                      caret
                      className={classnames({ active: this.state.activeTab[1] === 'd1' || this.state.activeTab[1] === 'd2' })}
                    >
                      Transactions
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className={classnames({ active: this.state.activeTab[1] === 'd1' })}
                        onClick={() => this.toggleTab(1, 'd1')}
                      >
                        Purchases
                      </DropdownItem>
                      <DropdownItem
                        className={classnames({ active: this.state.activeTab[1] === 'd2' })}
                        onClick={() => this.toggleTab(1, 'd2')}
                      >
                        Sales
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem disabled>Transaction Reports</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink 
                      href="#"
                      className={classnames({ active: this.state.activeTab[1] === 2 })}
                      onClick={() => this.toggleTab(1, 2)}
                    >
                      Settings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink disabled href="#">Reports</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab[1]}>
                  <TabPane tabId={1}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </TabPane>
                  <TabPane tabId="d1">                
                    Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                  </TabPane>
                  <TabPane tabId="d2">                
                    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </TabPane>
                  <TabPane tabId={2}>                
                    Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>

          <Col md="6">
            <Nav tabs>
              <NavItem>
                <NavLink 
                  href="#"
                  className={classnames({ active: this.state.activeTab[2] === 1 })}
                  onClick={() => this.toggleTab(2, 1)}
                >
                  My Profile
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle 
                  nav 
                  caret
                  className={classnames({ active: this.state.activeTab[2] === 'd1' || this.state.activeTab[2] === 'd2' })}
                >
                  Transactions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className={classnames({ active: this.state.activeTab[2] === 'd1' })}
                    onClick={() => this.toggleTab(2, 'd1')}
                  >
                    Purchases
                  </DropdownItem>
                  <DropdownItem
                    className={classnames({ active: this.state.activeTab[2] === 'd2' })}
                    onClick={() => this.toggleTab(2, 'd2')}
                  >
                    Sales
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem disabled>Transaction Reports</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink 
                  href="#"
                  className={classnames({ active: this.state.activeTab[2] === 2 })}
                  onClick={() => this.toggleTab(2, 2)}
                >
                  Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink disabled href="#">Reports</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab[2]}>
              <TabPane tabId={1}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </TabPane>
              <TabPane tabId="d1">                
                Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
              </TabPane>
              <TabPane tabId="d2">                
                Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </TabPane>
              <TabPane tabId={2}>                
                Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </TabPane>
            </TabContent>
          </Col>

          <Col md="6">
            <Nav pills>
              <NavItem>
                <NavLink 
                  href="#"
                  className={classnames({ active: this.state.activeTab[3] === 1 })}
                  onClick={() => this.toggleTab(3, 1)}
                >
                  My Profile
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle 
                  nav 
                  caret
                  className={classnames({ active: this.state.activeTab[3] === 'd1' || this.state.activeTab[3] === 'd2' })}
                >
                  Transactions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className={classnames({ active: this.state.activeTab[3] === 'd1' })}
                    onClick={() => this.toggleTab(3, 'd1')}
                  >
                    Purchases
                  </DropdownItem>
                  <DropdownItem
                    className={classnames({ active: this.state.activeTab[3] === 'd2' })}
                    onClick={() => this.toggleTab(3, 'd2')}
                  >
                    Sales
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem disabled>Transaction Reports</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink 
                  href="#"
                  className={classnames({ active: this.state.activeTab[3] === 2 })}
                  onClick={() => this.toggleTab(3, 2)}
                >
                  Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink disabled href="#">Reports</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab[3]}>
              <TabPane tabId={1}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </TabPane>
              <TabPane tabId="d1">                
                Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
              </TabPane>
              <TabPane tabId="d2">                
                Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </TabPane>
              <TabPane tabId={2}>                
                Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Quis nostrud. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default TabsDemo;