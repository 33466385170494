import React, { Fragment, Component } from 'react';
import ReactSelectDemo from './ReactSelectDemo';
import CreateableDemo from './CreatableDemo'; 

class AdvancedInputs extends Component {
  render() {
    return (
      <Fragment>
        <ReactSelectDemo />
        <CreateableDemo />        
      </Fragment>
    );
  }
}

export default AdvancedInputs;