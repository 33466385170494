import React, { Component } from 'react';
import { MdCloudUpload as IconUpload } from 'react-icons/md';
import { uniqueId } from 'lodash';
import './style.css';

class FileInput extends Component {
  state = {
    selected: ''
  }

  UNSAFE_componentWillMount() {
    if(this.props.id === undefined) {
      this.id = uniqueId('file');
    } else {
      this.id = this.props.id;
    }
  }

  fileChange = (e) => {
    let fileName = '';
    const files = e.target.files;

    if(files && files.length > 1) {
      fileName = ( e.target.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', files.length );
    } else {
      fileName = e.target.value.split( '\\' ).pop();
    }

    this.setState({
      selected: fileName
    });
  }

  render() {
    const {
      color,
      hastextfield,
      outline,
      multiple
    } = this.props;

    let labelText, className;

    if(multiple) {
      labelText = 'Choose files...'
    } else {
      labelText = 'Choose a file...'
    }

    if(outline) {
      className = `input-file input-file-outline${color ? `-${color}` : `` }`;
    } else {
      className = `input-file ${color ? `input-file-${color}` : `` }`;
    }

    const fileInputButton = (
      <div className="d-inline-block">
        <input 
          type="file" 
          id={this.id}
          onChange={this.fileChange} 
          className={className}
          data-multiple-caption="{count} files selected" 
          {...this.props}
        />
        <label htmlFor={this.id}><IconUpload size="16" /> 
          {this.state.selected ? this.state.selected : labelText}
        </label>
      </div>
    );
    
    const fileInputHasTextField = (
      <div className="file-input has-text">
        <input 
          type="file" 
          id={this.id} 
          onChange={this.fileChange}
          className={`input-file input-file-has-text ${color ? `input-file-${color}` : `` }`}
          data-multiple-caption="{count} files selected" 
          {...this.props}
          />
          <label htmlFor={this.id}>
            <span>{this.state.selected}</span>
            <strong><IconUpload size="16" />Choose a file...</strong>
          </label>
      </div>
    );

    return (
      hastextfield ? fileInputHasTextField : fileInputButton
    );
  }
}

export default FileInput;