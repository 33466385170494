import React, { Fragment, useState } from 'react';
import {
  Row, Col,
  Table
} from 'reactstrap';
import PageHeader from 'shared/components/page-header';
import AdvancedCard from 'shared/components/advanced-card';
import { 
  GoSync as IconRefresh,
  GoGear as IconSettings,
  GoPencil as IconEdit
} from 'react-icons/go';
import { 
  ToastContainer, 
  toast,
} from 'react-toastify';
import classnames from 'classnames';
import 'react-toastify/dist/ReactToastify.css';
import '../notifications/custom-styles.css';

export default (props) => {
  const [loading, setLoading] = useState(false);

  const load = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);          
    setLoading(true);
  }

  const [activeTabId, setActiveTabId] = useState('1');
  const [activeTabId2, setActiveTabId2] = useState('21');

  return(

    <Fragment>
      <PageHeader title="Advanced Cards" description="Cards with controls and features" />
    
      <Row>
        <Col lg="4">
          <AdvancedCard title="Card Controls" standard>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard title="Initially Closed" standard closed>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard 
            title="Card Options" 
            options={[
              {label: 'Refresh', icon: <IconRefresh /> },
              {label: 'Edit', icon: <IconEdit /> },
              {label: 'Settings', icon: <IconSettings /> }
            ]}
          >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard 
            title="Has Switch" 
            hasSwitch
            checked
            onChange={() => { /* do something */ }}
            >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard 
            title="Loading State" 
            loading={true}
            >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard 
            title="Loading State" 
            loading={true}
            loadingText='Loading...'
            >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard 
            title="Card Refresh" 
            refreshable
            loading={loading}
            onClick={load}
            >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard 
            title="Card Options" 
            buttons={[
              {title: 'Save', color: 'primary', onClick: () => toast.success('Data has been saved !') },
              {title: 'Reset', color: 'danger', onClick: () => toast.warn('Data has been reset') },
            ]}
          >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </AdvancedCard>
          <ToastContainer />
        </Col>
        <Col lg="4">
          <AdvancedCard 
            title="Card with Table"
            noPadding
          >
            <Table>
              <thead>
                <tr>
                  <th>Browser</th>
                  <th>OS</th>
                  <th>Visits</th>
                  <th>New Visits</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Firefox</td>
                  <td>Windows</td>
                  <td>210</td>
                  <td>83.11%</td>
                </tr>
                <tr>
                  <td>Firefox</td>
                  <td>Windows</td>
                  <td>210</td>
                  <td>83.11%</td>
                </tr>
                <tr>
                  <td>Firefox</td>
                  <td>Windows</td>
                  <td>210</td>
                  <td>83.11%</td>
                </tr>
              </tbody>
            </Table>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard
            title="Scrollable"
            scrollable
            height="160px"
          >
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse.
            </p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse.
            </p>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard
            tabs={
              [
                {id: '1', title: 'My Account'},
                {id: '2', title: 'Settings'}
              ]
            }
            activeTab={activeTabId}
            onTabClick={setActiveTabId}
          >
            <div className={`tab-pane fade ${classnames({ 'active show': activeTabId === '1' })}`} id="1">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                dolor in reprehenderit in voluptate velit esse.</p>
            </div>
            <div className={`tab-pane fade ${classnames({ 'active show': activeTabId === '2' })}`} id="2">
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                laboris nisi ut aliquip ex ea commodo consequat. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
            </div>
            <div className={`tab-pane fade ${classnames({ 'active show': activeTabId === '3' })}`} id="3">
              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                dolor in reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. </p>
            </div>
          </AdvancedCard>
        </Col>
        <Col lg="4">
          <AdvancedCard
            tabs={
              [
                {id: '21', title: 'My Account'},
                {id: '22', title: 'Settings'}
              ]
            }
            tabsLine
            activeTab={activeTabId2}
            onTabClick={setActiveTabId2}
          >
            <div className={`tab-pane fade ${classnames({ 'active show': activeTabId2 === '21' })}`} id="21">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                dolor in reprehenderit in voluptate velit esse.</p>
            </div>
            <div className={`tab-pane fade ${classnames({ 'active show': activeTabId2 === '22' })}`} id="22">
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse. Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                laboris nisi ut aliquip ex ea commodo consequat. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
            </div>
            <div className={`tab-pane fade ${classnames({ 'active show': activeTabId2 === '23' })}`} id="23">
              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
                dolor in reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. </p>
            </div>
          </AdvancedCard>
        </Col>
      </Row>
    </Fragment>
  );
}