import React, { Fragment, Component } from 'react';
import PageHeader from 'shared/components/page-header';
import {
  Row, Col, Card, CardBody, CardTitle
} from 'reactstrap';
import FileInput from 'shared/components/ui/FileInput';

export default class FileInputDemo extends Component {
  state = {
    selected: ''
  }

  fileChange = (e) => {

    let fileName = '';
    const files = e.target.files;

    if(files && files.length > 1) {
      fileName = ( e.target.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', files.length );
    } else {
      fileName = e.target.value.split( '\\' ).pop();
    }

    this.setState({
      selected: fileName
    });
  }

  render() {
    return (
      <Fragment>
        <PageHeader title="File Inputs" description="Advanced Custom File Inputs" />
        <Row>
          <Col lg="6">
            <Card>
              <CardBody>
                <CardTitle>Types</CardTitle>
                <Row>
                  <Col lg="6">
                    <p>Single File Selection</p>
                    <FileInput color="primary" />
                  </Col>
                  <Col lg="6">
                    <p>Multiple File Selection</p>
                    <FileInput color="primary" multiple/>
                  </Col>
                </Row>    

                <br />
            
                <p>With Text Field</p>
                <FileInput color="primary" hastextfield="true" />
                
                <br /><br />

                <p>Disabled</p>
                <FileInput disabled /> {' '}
                <FileInput disabled color="primary" /> {' '}
                <FileInput disabled outline="true" color="primary" /> {' '}
                <FileInput color="primary" hastextfield="true" disabled />    
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card>
              <CardBody>
                <CardTitle>Colors and Styles</CardTitle>

                <p>Contextual Colors</p>
                <FileInput /> {' '}
                <FileInput color="primary" /> {' '}
                <FileInput color="secondary" /> {' '}
                <FileInput color="success" /> {' '}
                <FileInput color="warning" /> {' '}
                <FileInput color="danger" /> {' '}
                <FileInput color="dark" />

                <br /><br />

                <p>Outline Styles</p>
                <FileInput color="primary" outline="true" /> {' '}
                <FileInput color="secondary" outline="true" /> {' '}
                <FileInput color="success" outline="true" /> {' '}
                <FileInput color="warning" outline="true" /> {' '}
                <FileInput color="danger" outline="true" /> {' '}
                <FileInput color="dark" outline="true" />

                <br /><br />
          
                <p>Contextual with Text Field</p>
                <FileInput hastextfield="true" /> {' '}
                <FileInput hastextfield="true" color="primary" /> {' '}
                <FileInput hastextfield="true" color="secondary" /> {' '}
                <FileInput hastextfield="true" color="success" /> {' '}
                <FileInput hastextfield="true" color="warning" /> {' '}
                <FileInput hastextfield="true" color="danger" /> {' '}
                <FileInput hastextfield="true" color="dark" />  
              </CardBody>
            </Card>            
          </Col>
        </Row>          
      </Fragment>
    );
  } 
}