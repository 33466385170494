import React from 'react';
import { 
  Card, CardBody, CardTitle,
  Row, Col
} from 'reactstrap';
import { Line, Bar, HorizontalBar, Pie, Doughnut, Radar }from 'react-chartjs-2';

const dataLine = {
  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL'],
  datasets: [
    {
      label: 'Sales',
      fill: false,
      lineTension: 0.1,
      backgroundColor: '#FFBB28',
      borderColor: '#524B83',
      borderCapStyle: 'butt',
      pointBorderWidth: 2,
      pointBackgroundColor: '#FFBB28',
      pointHoverRadius: 7,      
      pointRadius: 5,
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ]
};

const dataBar = (canvas) => {
  const ctx = canvas.getContext("2d")
  const gradient = ctx.createLinearGradient(0,0,0,300);
  gradient.addColorStop(0, '#524B83');
  gradient.addColorStop(1, '#7F76B9');

  return { 
    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL'],
    datasets: [
      {
        label: 'Sales',
        backgroundColor: gradient,
        hoverBackgroundColor: '#524B83',
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
  }
};

const dataMixed = {
  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL'],
  datasets: [{
    type: 'line',
    label: 'Sales',
    fill: false,
    lineTension: 0.1,
    backgroundColor: '#FFBB28',
    borderColor: '#FFBB28',
    borderCapStyle: 'butt',
    pointBorderWidth: 2,
    pointBackgroundColor: '#FFBB28',
    pointHoverRadius: 7,      
    pointRadius: 5,
    data: [65, 59, 80, 81, 56, 50, 70],
    yAxisID: 'y-axis-2'
  },
  {
    type: 'bar',
    label: 'Visitor',
    backgroundColor: '#524B83',
    hoverBackgroundColor: '#524B83',
    data: [580, 450, 600, 620, 390, 380, 420],
    yAxisID: 'y-axis-1'
  }]
}

const dataArea = (canvas) => {
  const ctx = canvas.getContext("2d")
  const gradient = ctx.createLinearGradient(0,0,0,300);
  gradient.addColorStop(0, '#FFBB28');
  gradient.addColorStop(1, '#FFF');

  return {    
    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL'],
    datasets: [
      {
        label: 'Sales',
        fill: true,
        lineTension: 0.1,
        backgroundColor: gradient,
        pointBorderWidth: 0,
        borderColor: '#FFBB28',
        data: [25, 45, 40, 68, 56, 55, 80]
      }
    ]
  }
};

const dataPie = {
	labels: [
		'Product A',
		'Product B',
		'Product C'
	],
	datasets: [{
		data: [300, 50, 100],
		backgroundColor: [
		'#524B83',
		'#FFBB28',
		'#FF8042'
		],
		hoverBackgroundColor: [
		'#635A9F',
		'#FFCD60',
		'#FFA980'
		]
	}]
};

const optionScales = {
  xAxes: [{
    gridLines: { color: 'transparent' },
    ticks: { min: 0, fontSize: 12, fontColor: '#b7bcc6' }
  }],
  yAxes: [{
    gridLines: { color: '#f0f1f3' },
    ticks: { min: 0, fontSize: 12, fontColor: '#b7bcc6' }  
  }]
};

const optionMixedScales = {
  xAxes: [{
    display: true,
    gridLines: {
      display: false
    },
    ticks: { fontSize: 12, fontColor: '#b7bcc6' },
  }],
  yAxes: [{
    type: 'linear',
    display: true,
    position: 'left',
    id: 'y-axis-1',
    gridLines: {
      display: false
    },
    scaleLabel: {
      display: true,
      labelString: 'Visitors'
    },
    ticks: { min: 0, fontSize: 12, fontColor: '#b7bcc6' }  
  },
  {
    type: 'linear',
    display: true,
    position: 'right',
    id: 'y-axis-2',
    gridLines: {
      display: false
    },
    scaleLabel: {
      display: true,
      labelString: 'Sales'
    },
    ticks: { min: 0, fontSize: 12, fontColor: '#b7bcc6' }  
  }]
};

const dataRadar = {
  labels: ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgb(256,187,40,.4)',
      borderColor: 'rgb(256,187,40)',
      pointBackgroundColor: 'rgb(256,187,40)',
      pointBorderColor: '#fff',
      data: [65, 59, 90, 81, 56, 55, 40]
    },
    {
      label: 'My Second dataset',
      backgroundColor: 'rgba(82,75,131,.4)',
      borderColor: 'rgb(82,75,131)',
      pointBackgroundColor: 'rgb(82,75,131)',
      pointBorderColor: '#fff',
      data: [28, 48, 40, 19, 96, 27, 100]
    }
  ]
};

export default (props) => (
  <Row>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Line Chart</CardTitle>
          <Line 
            data={dataLine} 
            options={{
              plugins: {
                datalabels: { display: false }
              },
              legend: { 
                position: 'bottom',
                labels: { usePointStyle: true }
              },
              scales: optionScales
            }}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Bar Chart</CardTitle>          
          <Bar
            data={dataBar}
            width={100}
            height={50}
            options={{
              plugins: {
                datalabels: { display: false }
              },
              scales: optionScales
            }}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Mixed Chart</CardTitle>          
          <Bar
            data={dataMixed}
            width={100}
            height={50}
            options={{
              plugins: {
                datalabels: { display: false }
              },
              scales: optionMixedScales
            }}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Area Chart</CardTitle>
          <Line 
            data={dataArea} 
            options={{
              plugins: {
                datalabels: { display: false }
              },
              legend: { 
                position: 'bottom',
              },
              scales: optionScales,
              elements: {
                point: { radius: 0 }
              }
            }}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Horizontal Bar Chart</CardTitle>          
          <HorizontalBar
            data={dataBar}
            width={100}
            height={50}
            options={{
              plugins: {
                datalabels: { display: false }
              },
              scales: optionScales
            }}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Pie Chart</CardTitle>

          <Pie 
            data={dataPie} 
            options={{
              plugins: {
                datalabels: { display: false }
              }
            }}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Doughnut Chart</CardTitle>

          <Doughnut 
            data={dataPie} 
            options={{
              plugins: {
                datalabels: { display: false }
              }
            }}
          />
        </CardBody>
      </Card>
    </Col>
    <Col md={6}>
      <Card>
        <CardBody>
          <CardTitle>Radar Chart</CardTitle>
          <Radar 
            data={dataRadar}
            options={{
              plugins: {
                datalabels: { display: false }
              }
            }}
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
  
);