import React from 'react';
import { CustomInput } from 'reactstrap';

const Radio = ({ options, name, onChange, checked = false }) => {
  return Object.keys(options).map(k => {
    const option = options[k];

    return (
        <CustomInput 
            key={`${name}-${option}`}
            id={option}
            type="radio"
            name={name}
            label={option}
            value={option}
            checked={option === checked}
            onChange={onChange}
        />
      
    );
  });
};

export default Radio;
