import React from 'react';
import './styles.css';

export default (props) => (
  <div className="page-header">
    <div className="left">
      <h2 className="page-title">{props.title}</h2>
      <p className="page-description">{props.description}</p>
    </div>
    {props.children &&
      <div className="right">
        { props.children }
      </div>
    }
  </div>
);