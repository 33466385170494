import React from 'react';
import {
  Row, Col,
  Card, CardBody, CardTitle
} from 'reactstrap';
import Slider, { createSliderWithTooltip, Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import './custom-style.css';

const style = { float: 'left', width: 160, height: 300, marginBottom: 50, marginLeft: 50 }

const marksNumber = {
  0: '0',
  50: '50',
  100: '100'
}

const marksMoney = {
  0: '$0',
  1000: '$1000'
}

const marksPercent = {
  0: '0%',
  100: '100%'
}

const marksDegree = {
  '-10': {
    style: {
      color: 'blue',
    },
    label: <strong>-10°C</strong>,
  },
  0: <strong>0°C</strong>,
  26: '26°C',
  37: '37°C',
  50: '50°C',
  100: {
    style: {
      color: 'red',
    },
    label: <strong>100°C</strong>,
  },
}

const SliderWithTooltip = createSliderWithTooltip(Slider);
const RangeWithTooltip = createSliderWithTooltip(Range);

function dollarFormatter(v) {
  return `$${v}`;
}

function percentFormatter(v) {
  return `${v}%`;
}

function celciusFormatter(v) {
  return `${v}°C`;
}

export default (props) => (
  <Row>
    <Col md="6">
      <Card>
        <CardBody>
          <CardTitle>Basic Slider</CardTitle>
          <p>Default</p>
          <Slider />

          <br />

          <p>Has Tooltip</p>
          <SliderWithTooltip min={0} max={100} defaultValue={3} />

          <br />

          <p>step=10</p>
          <SliderWithTooltip min={0} max={100} step={10} defaultValue={20} />

          <br />

          <p>step=10, dots</p>
          <SliderWithTooltip min={0} max={100} step={10} dots defaultValue={40} />

          <br />

          <p>Disabled</p>
          <Slider disabled />
        </CardBody>
      </Card>  
    </Col>
    <Col md="6">
      <Card>
        <CardBody>
          <CardTitle>Range Slider</CardTitle>
          <p>Basic Range, allowCross=false</p>
          <RangeWithTooltip allowCross={false} defaultValue={[0, 20]} />

          <br />

          <p>Basic Range, step=20</p>
          <RangeWithTooltip allowCross={false} step={20} defaultValue={[20, 60]} />

          <br />

          <p>Basic Range, step=20, dots</p>
          <RangeWithTooltip allowCross={false} step={20} dots defaultValue={[20, 60]} />

          <br />

          <p>Disabled</p>
          <RangeWithTooltip defaultValue={[40, 80]} disabled /> 
        </CardBody>
      </Card>
    </Col>
    <Col md="6">
      <Card>
        <CardBody>
          <CardTitle>Vertical Slider</CardTitle>
          <div style={style}>
            <p className="mb-4">With marks</p>
            <SliderWithTooltip
              min={-10}
              max={100}
              marks={marksDegree}
              defaultValue={36}
              tipFormatter={celciusFormatter}
              vertical
            />
          </div>

          <div style={style}>
            <p className="mb-4">Range</p>
            <RangeWithTooltip
              min={-10}
              max={100}
              marks={marksDegree}
              defaultValue={[20, 36]}
              tipFormatter={celciusFormatter}
              vertical
            />
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col md="6">
      <Card>
        <CardBody>
          <CardTitle>Slider Marks</CardTitle>
          <p>Number</p>
          <Slider
            min={0}
            max={100}
            marks={marksNumber}
            defaultValue={24}
          />

          <br />

          <p>Money</p>
          <SliderWithTooltip
            min={0}
            max={1000}
            marks={marksMoney}
            tipFormatter={dollarFormatter}
            defaultValue={340}
          />

          <br />

          <p>Percentage</p>
          <SliderWithTooltip
            min={0}
            max={100}
            marks={marksPercent}
            tipFormatter={percentFormatter}
            defaultValue={22}
          />
          
          <br />
          
          <p>Custom</p>
          <SliderWithTooltip
            min={-10}
            max={100}
            marks={marksDegree}
            defaultValue={36}
            tipFormatter={celciusFormatter}
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
)