import React, { Fragment } from 'react';
import PageHeader from 'shared/components/page-header';
import {
  Row, Col,
  Card, CardBody, CardTitle,
  Form, FormGroup, Label, Input, CustomInput
} from 'reactstrap';

export default () => (
  <Fragment>
    <PageHeader title="Basic Inputs" description="Basic form inputs" />
    <Row>
      <Col md="5">
        <Card>
          <CardBody>
            <CardTitle>Custom Inputs</CardTitle>
            <Form>
              <FormGroup>
                <Label for="exampleCheckbox">Checkboxes</Label>
                <div>
                  <CustomInput type="checkbox" id="customCheckbox" label="Check this custom checkbox" />
                  <CustomInput type="checkbox" id="customCheckbox2" label="Or this one" />
                  <CustomInput type="checkbox" id="customCheckbox3" label="But not this disabled one" disabled />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="exampleCheckbox">Radios</Label>
                <div>
                  <CustomInput type="radio" id="customRadio" name="customRadio" label="Select this custom radio" />
                  <CustomInput type="radio" id="customRadio2" name="customRadio" label="Or this one" />
                  <CustomInput type="radio" id="customRadio3" label="But not this disabled one" disabled />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="exampleCheckbox">Switches</Label>
                <div>
                  <CustomInput type="switch" id="customSwitch" name="customSwitch" label="Turn on this custom switch" />
                  <CustomInput type="switch" id="customSwitch2" name="customSwitch" label="Or this one" />
                  <CustomInput type="switch" id="customSwitch3" label="But not this disabled one" disabled />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="exampleCheckbox">Inline</Label>
                <div>
                  <CustomInput type="checkbox" id="customInline" label="An inline custom input" inline />
                  <CustomInput type="checkbox" id="customInline2" label="and another one" inline />
                </div>
                <div>
                  <CustomInput type="radio" id="customInlineRadio" name="customRadio" label="Inline custom radio" inline />
                  <CustomInput type="radio" id="customInlineRadio2" name="customRadio" label="and another one" inline />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="customFileBrowser">File Browser</Label>
                <CustomInput type="file" id="customFileBrowser" name="customFile" />
              </FormGroup>
              <FormGroup>
                <Label for="customFileBrowser2">File Browser with Custom Label</Label>
                <CustomInput type="file" id="customFileBrowser2" name="customFile" label="Select file to upload..." />
              </FormGroup>
              <FormGroup>
                <Label for="customFileBrowser3">File Browser Disabled</Label>
                <CustomInput type="file" id="customFileBrowser3" name="customFile" disabled />
              </FormGroup>
            </Form>
          </CardBody>
        </Card>        
        <Card>
          <CardBody>
            <CardTitle>Input Sizing</CardTitle>
            <Form>
              <Input placeholder="lg" bsSize="lg" /> <br />
              <Input placeholder="default" /> <br />
              <Input placeholder="sm" bsSize="sm" /> <br />
              <Input type="select" bsSize="lg">
                <option>Large Select</option>
              </Input> <br />
              <Input type="select">
                <option>Default Select</option>
              </Input> <br />
              <Input type="select" bsSize="sm">
                <option>Small Select</option>
              </Input>
            </Form>      
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <CardTitle>Default Inputs with basic style</CardTitle>
            <Form>
              <FormGroup>
                <Label for="inputText">Text</Label>
                <Input placeholder="Text input" id="inputText" />
              </FormGroup>
              <FormGroup>
                <Label for="inputEmail">Email</Label>
                <Input type="email" placeholder="Email input" id="inputEmail" />
              </FormGroup>
              <FormGroup>
                <Label for="inputPassword">Password</Label>
                <Input type="password" placeholder="Password input" id="inputPassword" />
              </FormGroup>
              <FormGroup>
                <Label for="inputTextarea">Textarea</Label>
                <Input type="textarea" placeholder="Textarea input" id="inputTextarea" />
              </FormGroup>
              <FormGroup>
                <Label for="inputSelect">Select</Label>
                <Input type="select" name="select" id="inputSelect">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="inputSelectMultiple">Multiple Select</Label>
                <Input type="select" name="select" id="inputSelectMultiple" multiple>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Radio Buttons</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" />{' '} Option 1
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="radio1" />{' '} Option 2
                  </Label>
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <Label>Checkboxes</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> {' '} Checkbox 1
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> {' '} Checkbox 2
                  </Label>
                </FormGroup>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>        
      </Col>
    </Row>
  </Fragment>
);

