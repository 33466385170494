import React, { Component } from 'react';
import {
  Row, Col,
  Card, CardBody, CardTitle,
  CustomInput
} from 'reactstrap';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './custom-styles.css';

class ReactDatesDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      propShowInputIcon: false,
      propDisabled: false,
      propDisabledStartDate: false,
      propRightInputIcon: false,
      propRightAnchor: false
    };
  }

  demoOnDtpChange = (propName) => {
    this.setState({
      [propName]: !this.state[propName]
    })
  }

  render() {
    return (
      <Card>
        <CardBody>
        <CardTitle>React Date Picker</CardTitle>
          <Row>
            <Col md="6" className="pt-2">
              <SingleDatePicker
                date={this.state.date}
                numberOfMonths={1}
                small={true}
                showClearDate
                onDateChange={date => this.setState({ date })}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                id="singledtp"
                showDefaultInputIcon={this.state.propShowInputIcon}
                inputIconPosition={this.state.propRightInputIcon ? 'after' : 'before'}
                disabled={this.state.propDisabled}
                anchorDirection={this.state.propRightAnchor ? 'right' : 'left'}
              />

            </Col>
            <Col md="6" className="pt-2">
              <DateRangePicker
                startDate={this.state.startDate}
                startDateId="startDate"
                endDate={this.state.endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                small={true}
                showClearDates
                showDefaultInputIcon={this.state.propShowInputIcon}
                inputIconPosition={this.state.propRightInputIcon ? 'after' : 'before'}
                disabled={
                  this.state.propDisabled ? true :
                  this.state.propDisabledStartDate ? "startDate" : false
                }
                anchorDirection={this.state.propRightAnchor ? 'right' : 'left'}
              />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md="4">
              <CustomInput id="showInputIcon" type="switch" defaultChecked={this.state.propShowInputIcon} label="Show Input Icon" onChange={() => this.demoOnDtpChange('propShowInputIcon')} />
              {this.state.propShowInputIcon &&
              <CustomInput id="rightInputIcon" type="switch" defaultChecked={this.state.propRightInputIcon} label="Right Input Icon" onChange={() => this.demoOnDtpChange('propRightInputIcon')} />
              }
            </Col>
            <Col md="4">
              <CustomInput id="disabled" type="switch" defaultChecked={this.state.propDisabled} label="Disabled State" onChange={() => this.demoOnDtpChange('propDisabled')} />
              <CustomInput id="disabledStart" type="switch" defaultChecked={this.state.propDisabledStartDate} label="Disable Start Date" onChange={() => this.demoOnDtpChange('propDisabledStartDate')} />
            </Col>
            <Col md="4">
              <CustomInput id="rightAnchor" type="switch" defaultChecked={this.state.propRightAnchor} label="Right Anchor (Calendar Popup)" onChange={() => this.demoOnDtpChange('propRightAnchor')} />
            </Col>
          </Row>
        </CardBody>
      </Card>    
    );
  }
};

export default ReactDatesDemo;