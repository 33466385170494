import React, { Component } from 'react';
import {
  Card, CardBody, CardTitle,
  Spinner
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import './style.css';
import { 
  FiUploadCloud as IconUpload, 
  FiFile as IconFile
} from 'react-icons/fi';
import { FiCheckCircle as IconCheck } from 'react-icons/fi';

class FileUploader extends Component {
  constructor (props) {
    super(props);

    this.state = {
      acceptedFiles: [],
      filesUploadedStatus:[]
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      acceptedFiles: acceptedFiles
    })

    // for demo purpose only, simulating backend
    let filesUploadedStatus = [];
    let interval = 500;

    acceptedFiles.forEach((file,idx) => {
      filesUploadedStatus[idx] = false;
      this.setState({
        filesUploadedStatus: filesUploadedStatus
      });

      setTimeout(() => {
        filesUploadedStatus[idx] = true;
        this.setState({
          filesUploadedStatus: filesUploadedStatus
        });
      }, interval);

      interval += 800;
    });
  }

  formatBytes = (bytes,decimals) => {
    if(bytes === 0) return '0 Bytes';

    let k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }

  render () {
    return (
      <Card>
        <CardBody>
          <CardTitle>Drag and Drop File Uploader</CardTitle>
          <Dropzone 
              onDrop={this.onDrop}
            >
            {({getRootProps, getInputProps}) => (
              <div className="dropzone-uploader">
                <div className="drop-area" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <IconUpload size="64" />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                { this.state.acceptedFiles.length > 0 &&
                  <div className="selected-files">
                    <ul>
                    {
                      this.state.acceptedFiles.map((file,idx) => (
                        <li key={file.name}>
                          <IconFile className="icon-file mr-1 text-muted" /> 
                          <strong className="filename">{file.name}</strong> <span className="filesize">({this.formatBytes(file.size)})</span>
                          {
                            this.state.filesUploadedStatus[idx] ? 
                            <IconCheck className="icon-check ml-2" /> :
                            <Spinner color="primary" size="sm" className="ml-2" />
                          }
                        </li>
                      ))
                    }
                    </ul>
                  </div>
                }
              </div>
            )}
          </Dropzone>
        </CardBody>
      </Card>
    )
  }
}

export default FileUploader;